$pale-grey: #f8f7fa;
$border-grey: #eeeeee;
$dusk: #7b7b7b;
$turquoise-blue: #00c3c0;
$blueberry-30: #403092;
$watermelon: #e96062;
$transparent-watermelon: #fcefef;
$sapphire: #3b2a98;
$sapphire-transparent: #3b2a9854;
$seaweed: #14c555;
$orangeish: #ed8b5b;
$pale-lilac: #e8e8e9;
$dark-blue-grey: #231a4a;
$sunflower-yellow: #ffea00;
$dark-yellow: #e09900;
$pending-yellow: #fc910d;
$transparent-yellow: #ffca593d;
$medium-yellow: #f7b500;
$turquoise-blue-two: #00b6d5;
$light-blue-grey: #e3f4ec;
$pale-grey-97: rgba(223, 221, 230, 0.97);
$cool-grey: #adacb6;
$black: #1d1d1c;
$blue-success: #00aeca;
$transparent-black: #1d1d1c1a;
$dark: #211b3e;
$shamrock-green: #00ca42;
$active-green: #02a758;
$transparent-active-green: #02a7582e;
$white: #ffffff;
$tomato: #e53935;
$orange: #ee8022;
$black_54: rgba(0, 0, 0, 0.54);
$greyish-brown: #4a4a4a;
$brown-grey: #9b9b9b;
$turquoise-blue-32: rgba(0, 183, 205, 0.32);
$black_87: rgba(0, 0, 0, 0.87);
$turquoise-12: rgba(0, 175, 203, 0.12);
$scarlet: #d0021b;
$very_light_pink: #e0e0e0;
$ice_blue_50: rgba(236, 253, 255, 0.5);
$ice_blue_20: rgba(226, 251, 255, 0.2);
$turquoise: #035857;
$theme-primary: #035857;
$turquoise-transparent: #00afcb4a;
$cloudy-blue: #c0becd;
$black_6: rgba(0, 0, 0, 0.06);
$coral: #ed6a5b;
$pale-grey-three: #e8e8eb;
$black_two: #444444;
$ice-blue: #f2fdff;
$ice-blue-two: #f0fcff;
$pale-grey-two: #eeecf9;
$blue-grey: #8d8b97;
$yellow-orange-5: rgba(247, 181, 0, 0.05);
$coral-10: rgba(237, 106, 91, 0.1);
$font-default: Avenir;
$font-medium: Avenir-Medium;
$font-light: Avenir-Light;
$font-bold: Avenir Next;
$light-font: light-font;
$roman-font: roman-font;
$regular-font: regular-font;
$bold-font: bold-font;
$extra-bold-font: extra-bold-font;
$medium-font: medium-font;
$font-default: Avenir;
$font-medium: Avenir-Medium;
$font-light: Avenir-Light;
$font-bold: Avenir Next;
$ping-fang-medium: ping-fang-medium;

$teal: #02b5b4;
$teal-two: #22c8c7;
$teal-three: #20c6c5;
$teal-four: #00a2a2;
