.help-support-content {
    padding: 1.875rem 3.75rem;
    background: linear-gradient(180deg, #FFFFFF 35.35%, #E0F6F6 100%);

    h2 {
        font-family: 'medium-font';
        line-height: 57.6px;
        font-size: 28px;
    }

    span, p {
        font-family: 'regular-font';
        font-size: 14px;
        line-height: 28px;
    }

    .bold {
        font-family: 'medium-font';
        font-size: 14px;
        line-height: 28px;
    }

    ul {
        list-style-type: none;

        li {
            margin-bottom: 10px;

            &::before {
                content: '👉';
                margin-right: 10px;
            }
        }
    }

    .mt-neg-10 {
        margin-top: -10px;
    }

    .mb-12 {
        margin-bottom: 12px;
    }

    .center {
        text-align: center;
    }

    .grid {
        display: grid;
        gap: 16px;
        grid-template-columns: auto auto;
        justify-content: space-around;
        margin-top: 30px;

        .phone-img {
            width: 300px;
        }
    }
}

@media only screen and (max-width: 768px) {
	.help-support-content {
		padding: 0.875rem 1.75rem;
        .grid {
            display: grid;
            grid-template-columns: auto;
        }
	}
}