

@font-face {
  font-family: 'light-font';
  src: url(./assets/fonts/Poppins-ExtraLight.ttf);
}

@font-face {
  font-family: 'regular-font';
  src: url(./assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: 'medium-font';
  src: url(./assets/fonts/Poppins-Medium.ttf);
}

@font-face {
  font-family: 'bold-font';
  src: url(./assets/fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: 'extra-bold-font';
  src: url(./assets/fonts/Poppins-ExtraBold.ttf);
}

body {
  margin: 0;
  font-family: 'normal-font';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: #7B7B7B; */
  overflow-x: hidden;
}
html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

*{
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  color: initial;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff8c;
  z-index: 99999999;
}

.loader img {
  width: 20%;
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  html, body {
    font-size: 1.125rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  html, body {
    font-size: 1.125rem;
  }
}

/* Extra small devices (phones, 320px and down) IPhone 5/SE */
@media only screen and (max-width: 320px) {
  html, body {
    font-size: 0.75rem;
  }
}
