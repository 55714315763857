@import './variables';

html,
body,
#root,
.app,
.router {
	position: relative;
	height: 100%;
	width: 100%;
	max-width: 1920px;
	margin: auto;
	font-size: 16px !important;
	-webkit-tap-highlight-color: transparent;
}

.flex {
	display: flex;
}

.justify-between {
	justify-content: space-between;
}

.justify-around {
	justify-content: space-around;
}

.justify-center {
	justify-content: center;
}

.align-center {
	align-items: center;
}

.flex-column {
	flex-direction: column;
}

.green {
	color: $active-green;
}

.red {
	color: $watermelon;
}

.fs-16 {
	font-size: 1rem;
}

.fs-18 {
	font-size: 1.125rem;
}

.fs-14 {
	font-size: 0.875rem;
}

.fs-13 {
	font-size: 0.815rem;
}

.fs-12 {
	font-size: 0.75rem;
}

.fs-11 {
	font-size: 0.7rem;
}

.fs-10 {
	font-size: 0.625rem;
}

.fs-20 {
	font-size: 1.25rem;
}

.fs-24 {
	font-size: 1.5rem;
}

.fs-26 {
	font-size: 1.625rem;
}

.fs-36 {
	font-size: 2.25rem;
}

.fs-45 {
	font-size: 2.8125rem;
}

.gap-4 {
	gap: 1rem;
}

.section-height {
	position: relative;
	min-height: 90vh;
	height: auto;
}

.mt-2 {
	margin-top: 2rem;
}

.mb-2 {
	margin-bottom: 2rem;
}

.mb-4 {
	margin-bottom: 4rem;
}

.h-auto {
	height: auto;
}

.w-70vw {
	width: 70vw;
}

.w-65vw {
	width: 65vw;
}

.w-60vw {
	width: 60vw;
}

.w-50vw {
	width: 50vw;
}

.w-40vw {
	width: 40vw;
}

.w-35vw {
	width: 35vw;
}

.w-30vw {
	width: 30vw;
}

.grid {
	display: grid;
}

.col-gap-4 {
	column-gap: 4rem;
}

.grid-col-4 {
	grid-template-columns: auto auto auto auto;
}

@media only screen and (max-width: 768px) {
	.flex-md-col {
		flex-direction: column;
	}
	.flex-md-row {
		flex-direction: row;
	}
	.section-height-md {
		position: relative;
		min-height: 50vh;
		height: auto;
	}
	.grid-md-col-2 {
		grid-template-columns: auto auto;
	}
	.md-justify-center {
		justify-content: center !important;
	}
}
