@import './variables';

.clr-cloudy-blue {
	color: $cloudy-blue;
}

.clr-primary {
	color: $theme-primary;
}

.clr-blue-grey {
	color: $blue-grey;
}

.clr-black {
	color: $black;
}

.clr-black-two {
	color: $black-two;
}

.clr-blue-success {
	color: $blue-success;
}

.clr-pale-grey-two {
	color: $pale-grey-two;
}

.clr-sapphire {
	color: $sapphire;
}

.clr-watermelon {
	color: $watermelon;
}

.clr-greyish-brown {
	color: $greyish-brown;
}

.clr-dark {
	color: $dark;
}

.clr-dusk {
	color: $dusk;
}

.clr-white {
	color: $white;
}

.clr-coral {
	color: $coral;
}

.clr-teal {
	color: $teal;
}

.clr-teal-2 {
	color: $teal-two;
}

.clr-teal-3 {
	color: $teal-three;
}

.bg-pale-grey {
	background: $pale-grey;
}

.bg-white {
	background: $white;
}

.bg-dusk {
	background: $dusk;
}

.bg-coral {
	background: $coral;
}

.bg-brown-grey {
	background: $brown-grey;
}

.bg-teal-four {
	background: $teal-four;
}

.font-light {
	font-family: 'light-font' !important;
}

.font-normal {
	font-family: 'normal-font';
}

.font-regular {
	font-family: 'regular-font';
}

.font-roman {
	font-family: 'roman-font';
}

.font-medium {
	font-family: 'medium-font';
}

.font-heavy {
	font-family: 'bold-font';
}

.font-extra-heavy {
	font-family: 'extra-bold-font';
}

.min-height-auto {
	min-height: auto;
}

.dsp-inb {
	display: inline-block;
}

.dsp-b {
	display: block;
}

.dsp-in {
	display: inline;
}

.dsp-flex {
	display: flex;
}

.flex-v-cntr {
	align-items: center;
}

.flex-wrap {
	flex-wrap: wrap;
}

.fs-16 {
	font-size: 1rem !important;
}

.fs-18 {
	font-size: 1.125rem !important;
}

.fs-14 {
	font-size: 0.875rem !important;
}

.fs-13 {
	font-size: 0.815rem;
}

.fs-12 {
	font-size: 0.75rem !important;
}

.fs-11 {
	font-size: 0.687rem;
}

.fs-10 {
	font-size: 0.625rem !important;
}

.fs-9 {
	font-size: 0.562rem;
}

.fs-8 {
	font-size: 0.5rem;
}

.fs-20 {
	font-size: 1.25rem;
}

.fs-22 {
	font-size: 1.375rem;
}

.fs-24 {
	font-size: 1.5rem !important;
}

.fs-36 {
	font-size: 2.25rem;
}

.fs-32 {
	font-size: 2rem;
}

.fs-42 {
	font-size: 4rem;
}

.fs-92 {
	font-size: 5.75rem;
	line-height: 6.5rem;
}

.fs-72 {
	font-size: 4.5rem;
	line-height: 4.5rem;
}

.fs-88 {
	font-size: 5.5rem;
	line-height: 5.5rem;
}

.fs-44 {
	font-size: 2.75rem;
	line-height: 2.75rem;
}

.fs-96 {
	font-size: 6rem;
	line-height: 6.5rem;
}

.isDisabled {
	pointer-events: none;
	opacity: 0.5;
}

.flex-both-cntr {
	display: flex;
	justify-content: center;
	align-items: center;
}

.justify-flex-end {
	justify-content: flex-end;
}

.justify-evenly {
	justify-content: space-evenly;
}

.align-flex-end {
	align-items: flex-end;
}

.align-flex-start {
	align-items: flex-start;
}

.space-between {
	justify-content: space-between;
}

.flt-rgt {
	float: right;
}

.flt-lft {
	float: left;
}

.clearfix:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: ' ';
	clear: both;
	height: 0;
}

.clearfix {
	display: inline-block;
}

.bottom-line {
	height: 1px;
	background: rgba(0, 0, 0, 0.1);
	border: none;
	margin: 0;
}

.border-bottom {
	border-bottom: 1px solid $pale-grey-two;
}

.br-6 {
	border-radius: 6px;
}

.no-border {
	border: none;
}

.mr-0 {
	margin: 0 !important;
}

.mr-8 {
	margin: 8px;
}

.mr-18 {
	margin: 18px;
}
.mb-0 {
	margin-bottom: 0 !important;
}

.mr-t-15 {
	margin-top: 15px;
}

.mr-t-30 {
	margin-top: 30px;
}

.mr-t-50 {
	margin-top: 50px;
}

.mr-t-25 {
	margin-top: 25px;
}

.mr-t-18 {
	margin-top: 18px;
}

.mr-t-20 {
	margin-top: 20px;
}

.mr-t-12 {
	margin-top: 12px;
}

.mr-t-10 {
	margin-top: 10px;
}

.mr-t-8 {
	margin-top: 8px;
}

.mr-r-5 {
	margin-right: 5px;
}

.m-r-30 {
	margin-right: 30px;
}

.mr-l-5 {
	margin-left: 5px;
}

.mr-l-10 {
	margin-left: 10px;
}

.mr-l-30 {
	margin-left: 30px;
}

.mr-t-5 {
	margin-top: 5px;
}

.mr-t-15 {
	margin-top: 15px;
}

.mr-b-5 {
	margin-bottom: 5px;
}

.mr-b-10 {
	margin-bottom: 10px;
}

.mr-b-60 {
	margin-bottom: 60px;
}

.mr-b-15 {
	margin-bottom: 15px;
}

.mr-b-25 {
	margin-bottom: 25px;
}

.mr-b-40 {
	margin-bottom: 40px;
}

.pd-h-5 {
	padding: 0 5px;
}

.mr-h-10 {
	margin: 0 10px;
}

.mr-h-5 {
	margin: 0 5px;
}

.mr-h-15 {
	margin: 0 15px;
}

.mr-h-40 {
	margin: 0 40px;
}

.mr-v-5 {
	margin: 5px 0;
}

.mr-v-15 {
	margin: 15px 0;
}

.mr-v-20 {
	margin: 20px 0;
}

.mr-v-10 {
	margin: 10px 0;
}

.h-100vh {
	height: 100vh;
}

.h-90vh {
	height: 90vh;
}

.h-10vh {
	height: 10vh;
}

.h-100 {
	height: 100%;
}

.h-50 {
	height: 50%;
}

.w-50 {
	width: 50%;
}

.w-25 {
	width: 25%;
}

.w-45 {
	width: 45%;
}

.w-25 {
	width: 25%;
	max-width: 25%;
}

.w-30 {
	width: 30%;
}

.w-33 {
	width: 33.3%;
}

.w-35 {
	width: 35%;
}

.w-85 {
	width: 85%;
}

.w-55 {
	width: 55%;
}

.w-60 {
	width: 60%;
}

.w-70 {
	width: 70%;
}

.w-75 {
	width: 75%;
}

.w-80 {
	width: 80%;
}

.w-90 {
	width: 90%;
}

.w-100 {
	width: 100%;
}

.w-110 {
	width: 110%;
}

.w-100vw {
	width: 100vw;
}

.w-10 {
	width: 10%;
}

.w-8 {
	width: 8%;
}

.w-0 {
	width: 0% !important;
}

.w-35 {
	width: 35%;
}

.algn-rgt {
	text-align: right;
}

.algn-lft {
	text-align: left;
}

.algn-cntr {
	text-align: center;
}

.align-v-cntr {
	vertical-align: middle;
}

.line-h20 {
	line-height: 20px;
}

.line-h35 {
	line-height: 35px;
}

.line-h45 {
	line-height: 45px;
}

.line-h80 {
	line-height: 80px;
}

.no-bold {
	font-weight: normal;
}

.bold-500 {
	font-weight: 500;
}

.bold-900 {
	font-weight: 900;
}

.pos-abs {
	position: absolute;
}

.pos-rel {
	position: relative;
}

.pos-fix {
	position: fixed;
}

.p-24 {
	padding: 24px;
}

.p-20 {
	padding: 20px;
}

.p-40 {
	padding: 40px;
}

.p-10 {
	padding: 10px;
}

.p-12 {
	padding: 12px;
}

.p-16 {
	padding: 16px;
}

.p-5 {
	padding: 5px;
}
.pb-120 {
	padding-bottom: 120px;
}
.pl-30 {
	padding-left: 30px;
}

.pb-20 {
	padding-bottom: 20px;
}

.pb-10 {
	padding-bottom: 10px;
}

.p-l-10 {
	padding-left: 10px;
}

.p-l-15 {
	padding-left: 15px;
}

.p-v-20 {
	padding-bottom: 20px;
	padding-top: 20px;
}

.underline {
	text-decoration: underline;
}

.italic {
	font-style: italic;
}

.no-wrap {
	white-space: nowrap;
}

.z-2 {
	z-index: 2;
}

.z-1 {
	z-index: 1;
}

.z-5 {
	z-index: 5;
}

.z-10 {
	z-index: 10;
}

.no-letter-spaceing {
	letter-spacing: normal;
}

.ovr-hdn {
	overflow: hidden;
}

.hard-padding {
	padding: 20px 100px;
}

.p-v-100 {
	padding: 100px 0;
}

.w-40 {
	width: 40%;
}
.w-20 {
	width: 20%;
}
.h-20 {
	height: 20%;
}
.mr-b-30 {
	margin-bottom: 30px;
}
.o-0 {
	opacity: 0;
}

.o-30 {
	opacity: 0.3;
}

.o-50 {
	opacity: 0.5;
}

.ul-style-decimal {
	list-style-type: decimal;
}

.pointer {
	cursor: pointer;
}

.word-break {
	word-break: break-word;
}

.uppercase {
	text-transform: uppercase;
}

.left-0 {
	left: 0;
}

.top-0 {
	top: 0;
}

.right-0 {
	right: 0;
}

.bottom-0 {
	bottom: 0;
}

.top-18 {
	top: 18rem;
}

.top-22 {
	top: 22rem;
}
